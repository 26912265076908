export const SESSION_USER_ROLE_BASE_FRAGMENT = `
  fragment sessionUserRoleBaseFragment on SessionUserRole {
    uid
    canModerateChat
    session {
        uid
        name
    }
    role {
        code
        name
    }
  }
`;
